import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from "rxjs";
import { ajax } from 'jquery';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  USERDATALIWA;
  projectid;
  withoutTicket: boolean = false;
  sedes;
  UserName;
  Id_Usuario;
  Id_Usuario_Busqueda;
  Id_Mantenimiento;
  Id_Client;
  Id_Client_Busqueda;
  banderaReagendamiento: boolean = false
  banderaModificaionTicket: boolean = false
  informacionBeneficiario;
  informacion;
  CLIENTDATALIWA;
  qAdminuser: number;
  qRestuser: number;
  Id_Ticket;
  ID_Enelar;
  Clientcolors = [{ clase: 'bg-gradient-primary', color: '#5e72e4' },
  { clase: 'bg-gradient-success', color: '#2dce89' },
  { clase: 'bg-gradient-suncoenergy', color: '#11cdef' },
  { clase: 'bg-gradient-sena', color: '#fb6340' },
  { clase: 'bg-gradient-danger', color: '#f5365c' },
  { clase: 'bg-gradient-dark', color: '#212529' },
  { clase: 'bg-gradient-default', color: '#172b4d' },
  { clase: 'bg-gradient-blue', color: '#093579' },
  { clase: 'bg-gradient-indigo', color: '#5603ad' },
  { clase: 'bg-gradient-purple', color: '#8965e0' },
  { clase: 'bg-gradient-pink', color: '#f3a4b5' },
  { clase: 'bg-gradient-red', color: '#f5365c' },
  { clase: 'bg-gradient-yellow', color: '#ffd600' },
  { clase: 'bg-gradient-cyan', color: '#00bba4' },
  ];

  selectedForVisit;
  isEnd;
  idEdit;
  selectedSede;
  idEditProject;
  idBenefit;
  idForm;
  idDispositivo;
  idLink;
  porcentaje: any[] = [];
  porcentaje_nazareth: any[] = [];
  isCC: boolean = false;
  isLc: boolean = false;
  isDp: boolean = false;
  onlyView:boolean = false;
  idProsec;
  idSeccional;
  nombre_tecnico
  idTecnico;
  mantenimientos: any[] = [];
  nombreSeccional;
  nombreProyecto;
  idProyectoSelect;
  lastSession;
  nuevoMed;
  sessionFlag: boolean = false;
  Clientdata;
  bSetup: any[] = [];
  arrCo2: any = [];
  arrArboles: any[] = [];
  arrPesos: any[] = [];
  arrInventario: any[] = [];
  PostesTabla: any[] = [];
  idRole;
  searchValue:string = null;
  edictMantenimiento
  selectedDate;
  mapStyle:any [] =[
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    }
  ];
  donePoll;
    //GEB
  ProjectData;
  DeviceData;
  TItulo;
  fase1Flag:boolean = false;

  messageUrl = 'https://liwa-web-yzotmrdvwq-uc.a.run.app/api/sms';
  municipalitiesUrl = 'https://www.datos.gov.co/resource/xdk5-pm3f.json';
  provincesUrl = 'https://www.datos.gov.co/resource/25e3-npng.json';

  mainUrl = 'https://www.php.engenius.com.co/DatabaseLIWA.php';
  urlprueba;

  isAgended = false;


  constructor(private route: Router, private http: HttpClient) {
    this.urlprueba = 'https://node-sql-e.herokuapp.com/';
  }



  /* servicio drive */
  generarPdfsPost(ids : any) : Promise<any>{
    const bodyR = {
      "idEncuestas":ids
  }
    return this.http.post('http://localhost:5000/ActasMantenimiento', bodyR, {responseType: 'blob'}).toPromise();
  }


  /* consultar localmente */
  consultarLocal(datos, respuesta) {
    try{
      fetch('https://servicio-dispower-yzotmrdvwq-uc.a.run.app', {
       //fetch('http://localhost:8080/', {
        method: 'POST',
        body:JSON.stringify(datos),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then((response) => response.json()).then((res)=> {
        console.log(res)
        respuesta(null,res)
      }).catch((err) => {
        respuesta(err)
      });
    }catch (err) {
      console.log(err)
    }
  }

  consultar(datos, respuesta) {
    try {
       fetch('https://servico-aom-yzotmrdvwq-uc.a.run.app',{
        // fetch('http://localhost:8080',{
        method: 'POST',
        body:JSON.stringify(datos),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then((response) => response.json()).then((res)=> {
        console.log(res)
        respuesta(null,res)
      }).catch((err) => {
        respuesta(err)
        // console.log('mencontraste3')
      });

     } catch (err) {
    console.log(err)
     }

  }

  consultar_siigo(datos, respuesta) {
    try {
       fetch('https://api-siigo-dispower-yzotmrdvwq-uc.a.run.app',{
        //fetch('http://localhost:8080',{
        method: 'POST',
        body:JSON.stringify(datos),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then((response) => response.json()).then((res)=> {
        console.log(res)
        respuesta(null,res)
      }).catch((err) => {
        respuesta(err)
      });
     } catch (err) {
      console.log(err)
     }

  }

  consultar2(datos, respuesta) {

      ajax({
        data: datos, cache: false, dataType: 'json', type: 'post',
        url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success(data, textStatus, jQxhr) {
            console.warn('response data', data);
                },
        error(jqXhr, textStatus, errorThrown) {
          // console.log('mencontraste')
          console.warn(errorThrown);
          console.warn(jqXhr);
          console.warn(textStatus);

        }
      }).then((response) => {
        respuesta(null, response);
        // console.log('mencontraste 2')
      }).catch((err) => {
        respuesta(err)
        // console.log('mencontraste3')
      });
  }

  consultarActas(datos, respuesta) {
    /*  FILTRAR SI ES SUNPOWER O OTRO*/
    try {
      fetch('localhost:5000/SendGoogleDrive', {
        method: 'POST',
          body:JSON.stringify(datos),
          headers:{
            'Content-Type': 'application/json'
          }
      }).then((response) => response.json()).then((res)=> {
        console.log("respueta acta pdf" + res);
        respuesta(null, res)
      }).catch((err) => {
        console.error(err);
      })
    } catch(err) {
      console.error(err)
    }
  }

  consultarFormatoSui(datos, respuesta) {

       try {
        fetch('https://sevicio-formatos-sui-yzotmrdvwq-uc.a.run.app',{
          method: 'POST',
          body:JSON.stringify(datos),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then((response) => response.json()).then((res)=> {
          console.log(res)
          respuesta(null,res)
        }).catch((err) => {
          respuesta(err)
          // console.log('mencontraste3')
        });

       } catch (err) {
      console.log(err)
       }

  }
  consultarFormatoSui22Fecth(datos, respuesta) {
    console.log('Consultando fetch');
    // try {
      fetch('http://localhost:3001',{
        method: 'POST',
        body:JSON.stringify(datos),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then((response) => response.json()).then((res)=> {
        console.log(res)
        respuesta(null,res)
      }).catch((err) => {
        respuesta(err)
        // console.log('mencontraste3')
      });

    // } catch (err) {
    // console.log(err)
    // }
  }
  consultarSMS(datos, respuesta) {
    // console.log('Consultando');
    // try {
      ajax({
        data: datos, cache: false, dataType: 'json', type: 'post',
        url: 'https://api-sms-yzotmrdvwq-uc.a.run.app/api/sms',
        success(data, textStatus, jQxhr) {
            console.warn('response data SMS', data);
                },
        error(jqXhr, textStatus, errorThrown) {
          // console.log('mencontraste')
          console.warn(errorThrown);
          console.warn(jqXhr);
          console.warn(textStatus);

        }
      }).then((response) => {
        respuesta(null, response);
        // console.log('mencontraste 2')
      }).catch((err) => {
        respuesta(err)
        // console.log('mencontraste3')
      });

    // } catch (err) {
    // console.log(err)
    // }
  }
  consultarvv(datos, respuesta) {
    // console.log('Consultando');
    ajax({
      data: datos, cache: false, dataType: 'json', type: 'post',
      url: 'https://www.php.engenius.com.co/DatabaseDashBoard.php',
      success(data, textStatus, jQxhr) {
        console.warn('bien', data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.warn('mal');
      }
    }).then((response) => {
      respuesta(null, response);
    }).catch((err) => {
      respuesta(err);
    });
  }
  consultarSENA(datos, respuesta) {
    // console.log('Consultando');
    ajax({
      data: datos, cache: false, dataType: 'json', type: 'post',
      url: 'https://www.php.engenius.com.co/DatabaseSAAS.php',
      success(data, textStatus, jQxhr) {
        console.warn('bien', data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.warn(errorThrown);
        console.warn(jqXhr);
        console.warn(textStatus);
      }
    }).then((response) => {
      respuesta(null, response);
    }).catch((err) => {
      respuesta(err);
    });
  }
  consultarDAS(datos, respuesta) {
    // console.log('Consultando');
    ajax({
      data: datos, cache: false, dataType: 'json', type: 'post',
      url: 'https://www.php.engenius.com.co/DatabaseDashBoard.php',
      success(data, textStatus, jQxhr) {
        console.warn('bien', data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.warn('mal');
      }
    }).then((response) => {
      respuesta(null, response);
    }).catch((err) => {
      respuesta(err);
    });
  }
  consultarEnelar(datos, respuesta) {
    // console.log('Consultando');
    ajax({
      data: datos, cache: false, dataType: 'json', type: 'post',
      url: 'https://www.php.engenius.com.co/Database.php',
      success(data, textStatus, jQxhr) {
        console.warn('bien', data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.warn('mal');
      }
    }).then((response) => {
      respuesta(null, response);
    }).catch((err) => {
      respuesta(err);
    });
  }
  initData() {
    const vari = localStorage.getItem('USERDATALIWA');
    if (vari != null) {
      this.USERDATALIWA = vari.split(',');
      // console.log('USERDATALIWA', this.USERDATALIWA);
      this.UserName = this.USERDATALIWA[3];
      this.Id_Usuario = this.USERDATALIWA[0];
      this.lastSession = this.USERDATALIWA[19];
      this.idRole = this.USERDATALIWA[2];
      const client = localStorage.getItem('CLIENTDATALIWA');
      this.CLIENTDATALIWA = client.split(',');
      this.Clientdata = this.CLIENTDATALIWA;
      // console.log('CLIENTDATALIWA', this.CLIENTDATALIWA);
      this.Id_Client = this.CLIENTDATALIWA[0];
      this.validSession();
    } else {
      this.route.navigateByUrl('/login');
    }

  }
  validSession() {
    let now = moment().format('YYYY-MM-DD');
    let last = this.lastSession;
    let dayNow = now.split('-')[2];
    let lastDay = last.split('-')[2];
    if (dayNow !== lastDay) {
      localStorage.clear();
      this.route.navigateByUrl('/login');
    }
  }
  sendMessage(messageModel: any): Observable<any> {
    return this.http.post(this.messageUrl, messageModel);
  }
  returnProvinces(): Observable<any> {
    return this.http.get(this.municipalitiesUrl);
  }
  momentFromNow(date: any) {
    moment.lang('es');
    var diff = moment(date).fromNow();
    return diff;
  }
  pruebaSena(datos: any): Observable<any> {
    const bodyrequest = datos;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json; charset=UTF-8'
      })
    }


    return this.http.post(this.urlprueba, bodyrequest, httpOptions);
  }
}
